import { createReducer, on } from "@ngrx/store";
import * as ProductActions from "../actions/product.actions";
import { productAdapter, ProductState } from "../../models";

const initialState = {
  ...productAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    ProductActions.loadProducts,
    (state): ProductState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    ProductActions.loadProductsSuccess,
    (state, { products }): ProductState =>
      productAdapter.setAll(products, { ...state, loading: false, loaded: true })
  ),
  on(
    ProductActions.loadProductsFailure,
    (state): ProductState => ({ ...state, loading: false, loaded: false })
  )
);

export const getProductsLoading = (state: ProductState) => state.loading;
export const getProductsLoaded = (state: ProductState) => state.loaded;
