import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import { OrganizationService } from "../../services";
import {
  loadOrganizationAssignableRoles,
  loadOrganizationAssignableRolesFailure,
  loadOrganizationAssignableRolesSuccess
} from "../actions";

@Injectable()
export class OrganizationAssignableRoleEffects {
  loadOrganizationAssignableRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganizationAssignableRoles),
      concatMap((organizationId) =>
        this.organizationService.getAssignableRoles(organizationId.organizationId).pipe(
          map((role) =>
            loadOrganizationAssignableRolesSuccess({ organizationAssignableRole: role })
          ),
          catchError(() => of(loadOrganizationAssignableRolesFailure()))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService
  ) {}
}
