import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { Invitation, Location, Organization, Ou, Role, RoleScope } from "../../models";
import {
  selectAllMails,
  selectAllOrganizationsLocationsOus,
  selectCurrentOrganization,
  selectCurrentOrganizationAssignableRoles
} from "../../store";

@Component({
  selector: "app-invitation-form",
  templateUrl: "./invitation-form.component.html",
  styleUrl: "./invitation-form.component.scss"
})
export class InvitationFormComponent implements OnInit {
  @Input() invitation!: Invitation;

  @Output() saveEmitter = new EventEmitter<Invitation>();
  @Output() cancelEmitter = new EventEmitter<void>();
  mails$: Observable<Set<string>>;
  namedEntitiesByScope$: Observable<{
    organizations: Organization[];
    locations: Location[];
    ous: Ou[];
  }>;
  availableRoles$: Observable<Role[]>;
  currentOrganization$: Observable<Organization | undefined>;
  invitationFormGroup!: FormGroup;
  protected readonly RoleScope = RoleScope;

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {
    this.mails$ = this.store.select(selectAllMails);
    this.namedEntitiesByScope$ = this.store.select(selectAllOrganizationsLocationsOus);
    this.availableRoles$ = this.store.select(selectCurrentOrganizationAssignableRoles);
    this.currentOrganization$ = this.store.select(selectCurrentOrganization);
  }

  ngOnInit(): void {
    this.initInvitationFormGroup(this.invitation);

    // Subscribe to form value changes
    this.invitationFormGroup.valueChanges.subscribe(() => {
      this.updateAvailableRoles();
    });

    // Initial update of available roles
    this.updateAvailableRoles();
  }

  save() {
    if (!this.invitationFormGroup.valid) {
      return;
    }

    const invitation = {
      id: this.invitation.id,
      inviteeMailAddress: this.invitationFormGroup.controls["inviteeMailAddress"].value[0],
      scope: this.invitationFormGroup.controls["scopeAndEntity"].value[0].scope,
      entityId: this.invitationFormGroup.controls["scopeAndEntity"].value[0].entityId,
      roleName: this.invitationFormGroup.controls["role"].value[0]
    } as Invitation;

    this.saveEmitter.emit(invitation);
  }

  cancel() {
    this.cancelEmitter.emit();
  }

  private initInvitationFormGroup(invitation: Invitation) {
    this.invitationFormGroup = this.fb.group({
      inviteeMailAddress: new FormControl(invitation.inviteeMailAddress, [
        Validators.required,
        Validators.email
      ]),
      scopeAndEntity: new FormControl(
        invitation.scope && invitation.entityId
          ? [{ scope: invitation.scope, entityId: invitation.entityId }]
          : [],
        [Validators.required]
      ),
      role: new FormControl(invitation.roleName, [Validators.required])
    });
  }

  private updateAvailableRoles() {
    this.availableRoles$ = this.store.select(selectCurrentOrganizationAssignableRoles).pipe(
      map((roles) =>
        roles.filter((role) => {
          let selectedScope = this.invitationFormGroup.controls["scopeAndEntity"].value[0]?.scope;
          return role.assignableScopes.includes(selectedScope);
        })
      )
    );
  }
}
