import { createSelector } from "@ngrx/store";

import { MyOrganizationState, productAdapter, ProductState } from "../../models";
import { productsReducer, selectMyOrganizationsState } from "../reducers";

const { selectAll } = productAdapter.getSelectors();

export const selectProductsState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.products as ProductState
);

export const selectProducts = createSelector(selectProductsState, selectAll);

export const selectProductsLoading = createSelector(
  selectProductsState,
  productsReducer.getProductsLoading
);

export const selectProductsLoaded = createSelector(
  selectProductsState,
  productsReducer.getProductsLoaded
);
