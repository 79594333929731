import { createAction, props } from "@ngrx/store";
import { Role } from "../../models";

export const loadOrganizationAssignableRoles = createAction(
  "[Organization/AssignableRole/API] Select Organization AssignableRole",
  props<{ organizationId: string }>()
);
export const loadOrganizationAssignableRolesSuccess = createAction(
  "[Organization/AssignableRole/API] Select Organization AssignableRole Success",
  props<{ organizationAssignableRole: Role[] }>()
);
export const loadOrganizationAssignableRolesFailure = createAction(
  "[Organization/AssignableRole/API] Select Organization AssignableRole Failure"
);
