import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import {
  loadMyRoleAssignments,
  loadMyRoleAssignmentsFailure,
  loadMyRoleAssignmentsSuccess
} from "../actions/my-role-assignment.actions";
import { MyRoleAssignmentService } from "../../services/my-role-assignment.service";

@Injectable()
export class MyRoleAssignmentEffects {
  loadMyRoleAssignments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMyRoleAssignments),
      switchMap(() =>
        this.myRoleAssignmentService.getMyRoleAssignments().pipe(
          map((roleAssignments) =>
            loadMyRoleAssignmentsSuccess({ roleAssignments: roleAssignments })
          ),
          catchError(() => of(loadMyRoleAssignmentsFailure()))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private myRoleAssignmentService: MyRoleAssignmentService
  ) {}
}
