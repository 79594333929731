import { createReducer, on } from "@ngrx/store";
import * as OrganizationAssignableRoleActions from "../actions/organization-assignable-role.actions";
import {
  organizationAssignableRoleAdapter,
  OrganizationAssignableRoleState
} from "../../models/organization-assignable-role.state";

const initialState = {
  ...organizationAssignableRoleAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    OrganizationAssignableRoleActions.loadOrganizationAssignableRoles,
    (state): OrganizationAssignableRoleState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    OrganizationAssignableRoleActions.loadOrganizationAssignableRolesSuccess,
    (state, { organizationAssignableRole }): OrganizationAssignableRoleState =>
      organizationAssignableRoleAdapter.setAll(organizationAssignableRole, {
        ...state,
        loading: false,
        loaded: true
      })
  ),
  on(
    OrganizationAssignableRoleActions.loadOrganizationAssignableRolesFailure,
    (state): OrganizationAssignableRoleState => ({ ...state, loading: false, loaded: false })
  )
);

export const getOrganizationAssignableRoleLoading = (state: OrganizationAssignableRoleState) =>
  state.loading;
export const getOrganizationAssignableRoleLoaded = (state: OrganizationAssignableRoleState) =>
  state.loaded;
