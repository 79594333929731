import { createAction, props } from "@ngrx/store";
import { Product } from "../../models";

export const loadOrganizationAssociatedProducts = createAction(
  "[Organization/AssociatedProduct/API] Select Organization AssociatedProduct",
  props<{ organizationId: string }>()
);
export const loadOrganizationAssociatedProductsSuccess = createAction(
  "[Organization/AssociatedProduct/API] Select Organization AssociatedProduct Success",
  props<{ organizationAssociatedProduct: Product[] }>()
);
export const loadOrganizationAssociatedProductsFailure = createAction(
  "[Organization/AssociatedProduct/API] Select Organization AssociatedProduct Failure"
);
export const associateProductToOrganization = createAction(
  "[Organization/AssociatedProduct/API] Associate Product To Organization",
  props<{ organizationId: string; product: Product }>()
);
export const associateProductToOrganizationSuccess = createAction(
  "[Organization/AssociatedProduct/API] Associate Product To Organization Success",
  props<{ organizationId: string; product: Product }>()
);
export const associateProductToOrganizationFailure = createAction(
  "[Organization/AssociatedProduct/API] Associate Product To Organization Failure"
);
export const disassociateProductFromOrganization = createAction(
  "[Organization/AssociatedProduct/API] Disassociate Product From Organization",
  props<{ organizationId: string; product: Product }>()
);
export const disassociateProductFromOrganizationSuccess = createAction(
  "[Organization/AssociatedProduct/API] Disassociate Product From Organization Success",
  props<{ organizationId: string; product: Product }>()
);
export const disassociateProductFromOrganizationFailure = createAction(
  "[Organization/AssociatedProduct/API] Disassociate Product From Organization Failure"
);
