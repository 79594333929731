import { createSelector } from "@ngrx/store";

import {
  MyOrganizationState,
  organizationAssociatedProductAdapter,
  OrganizationAssociatedProductState,
  Product
} from "../../models";
import { organizationAssociatedProductReducer, selectMyOrganizationsState } from "../reducers";

const { selectEntities } = organizationAssociatedProductAdapter.getSelectors();

export const selectOrganizationsAssociatedProductState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) =>
    state.organizationAssociatedProducts as OrganizationAssociatedProductState
);

export const selectOrganizationAssociatedProductEntities = createSelector(
  selectOrganizationsAssociatedProductState,
  selectEntities
);

export const selectCurrentOrganizationAssociatedProducts = createSelector(
  selectOrganizationAssociatedProductEntities,
  (organizationAssociatedProducts): Product[] => {
    return Object.values(organizationAssociatedProducts).filter(
      (issuer) => issuer !== undefined
    ) as Product[];
  }
);
createSelector(
  selectOrganizationsAssociatedProductState,
  organizationAssociatedProductReducer.getOrganizationAssociatedProductLoading
);
createSelector(
  selectOrganizationsAssociatedProductState,
  organizationAssociatedProductReducer.getOrganizationAssociatedProductLoaded
);
