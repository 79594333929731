import { OrganizationsComponent } from "./organizations/organizations.component";
import { OrganizationComponent } from "./organization/organization.component";
import { LocationsComponent } from "./locations/locations.component";
import { OusComponent } from "./ous/ous.component";
import { CountryService } from "../../core/services";
import { IssuersComponent } from "./issuers/issuers.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { ProductsComponent } from "./products/products.component";

export const containers: any[] = [
  IssuersComponent,
  LocationsComponent,
  OrganizationComponent,
  OrganizationsComponent,
  OusComponent,
  ProductsComponent,
  UserManagementComponent
];

export const services: any[] = [CountryService];

export * from "./issuers/issuers.component";
export * from "./locations/locations.component";
export * from "./organization/organization.component";
export * from "./organizations/organizations.component";
export * from "./ous/ous.component";
export * from "./user-management/user-management.component";
