import { createSelector } from "@ngrx/store";
import { MyAccountState } from "../../models";
import {
  MyRoleAssignmentAdapter,
  MyRoleAssignmentState
} from "../../models/my-role-assignment.state";
import { myRoleAssignmentsReducer, selectMyAccountState } from "../reducers";

const { selectAll } = MyRoleAssignmentAdapter.getSelectors();

export const selectMyRoleAssignmentsState = createSelector(
  selectMyAccountState,
  (state: MyAccountState) => state.myRoleAssignments as MyRoleAssignmentState
);

export const selectAllMyRoleAssignments = createSelector(selectMyRoleAssignmentsState, selectAll);

export const selectMyRoleAssignmentsLoading = createSelector(
  selectMyRoleAssignmentsState,
  myRoleAssignmentsReducer.getMyRoleAssignmentsLoading
);

export const selectMyRoleAssignmentsLoaded = createSelector(
  selectMyRoleAssignmentsState,
  myRoleAssignmentsReducer.getMyRoleAssignmentsLoaded
);
