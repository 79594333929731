import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface Product {
  id: string;
  name: string;
}

export interface ProductState extends EntityState<Product> {
  loading: boolean;
  loaded: boolean;
}

export const productAdapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (product) => product.id,
  sortComparer: (product1, product2) => product1.name.localeCompare(product2.name)
});
