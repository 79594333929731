import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { MyOrganizationState } from "../../models";

import { reducer as invitationsReducer } from "./invitation.reducer";
import { reducer as locationsReducer } from "./location.reducer";
import { reducer as organizationAssignableRoleReducer } from "./organization-assignable-role.reducer";
import { reducer as organizationAssociatedProductReducer } from "./organization-associated-product.reducer";
import { reducer as organizationIssuerReducer } from "./organization-issuer.reducer";
import { reducer as organizationsReducer } from "./organization.reducer";
import { reducer as ousReducer } from "./ou.reducer";
import { reducer as productsReducer } from "./product.reducer";
import { reducer as roleAssignmentsReducer } from "./role-assignment.reducer";

export const reducers: ActionReducerMap<MyOrganizationState> = {
  invitations: invitationsReducer,
  locations: locationsReducer,
  organizationAssignableRoles: organizationAssignableRoleReducer,
  organizationAssociatedProducts: organizationAssociatedProductReducer,
  organizationIssuers: organizationIssuerReducer,
  organizations: organizationsReducer,
  ous: ousReducer,
  products: productsReducer,
  roleAssignments: roleAssignmentsReducer
};

export const selectMyOrganizationsState =
  createFeatureSelector<MyOrganizationState>("my-organizations");

export * as invitationsReducer from "./invitation.reducer";
export * as locationsReducer from "./location.reducer";
export * as organizationAssignableRoleReducer from "./organization-assignable-role.reducer";
export * as organizationAssociatedProductReducer from "./organization-associated-product.reducer";
export * as organizationIssuerReducer from "./organization-issuer.reducer";
export * as organizationsReducer from "./organization.reducer";
export * as ousReducer from "./ou.reducer";
export * as productsReducer from "./product.reducer";
export * as roleAssignmentsReducer from "./role-assignment.reducer";
