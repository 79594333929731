import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngrx/store";
import { catchError, combineLatest, Observable, of, switchMap, take, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import {
  loadMyInvitations,
  loadMyRoleAssignments,
  selectMyInvitationsLoaded,
  selectMyRoleAssignmentsLoaded
} from "../store";

export const myAccessCanActivate: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);

  return checkStore(store).pipe(
    switchMap(() => of(true)),
    catchError(() => of(false))
  );
};

function checkStore(store: Store): Observable<boolean> {
  const myInvitationsLoaded$ = store.select(selectMyInvitationsLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(loadMyInvitations());
      }
    }),
    filter((loaded) => loaded),
    take(1)
  );

  const myRoleAssignmentsLoaded$ = store.select(selectMyRoleAssignmentsLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(loadMyRoleAssignments());
      }
    }),
    filter((loaded) => loaded),
    take(1)
  );

  return combineLatest([myInvitationsLoaded$, myRoleAssignmentsLoaded$]).pipe(
    map(
      ([invitationsLoaded, myRoleAssignmentsLoaded$]) =>
        myInvitationsLoaded$ && myRoleAssignmentsLoaded$
    )
  );
}
