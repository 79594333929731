import { createSelector } from "@ngrx/store";

import {
  MyOrganizationState,
  organizationAssignableRoleAdapter,
  OrganizationAssignableRoleState,
  Role
} from "../../models";
import { organizationAssignableRoleReducer, selectMyOrganizationsState } from "../reducers";

const { selectEntities } = organizationAssignableRoleAdapter.getSelectors();

export const selectOrganizationsAssignableRoleState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) =>
    state.organizationAssignableRoles as OrganizationAssignableRoleState
);

export const selectOrganizationAssignableRoleEntities = createSelector(
  selectOrganizationsAssignableRoleState,
  selectEntities
);

export const selectCurrentOrganizationAssignableRoles = createSelector(
  selectOrganizationAssignableRoleEntities,
  (organizationAssignableRoles): Role[] => {
    return Object.values(organizationAssignableRoles).filter(
      (issuer) => issuer !== undefined
    ) as Role[];
  }
);
createSelector(
  selectOrganizationsAssignableRoleState,
  organizationAssignableRoleReducer.getOrganizationAssignableRoleLoading
);
createSelector(
  selectOrganizationsAssignableRoleState,
  organizationAssignableRoleReducer.getOrganizationAssignableRoleLoaded
);
