import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RoleScope } from "./role-scope";

export interface HealthUser {
  id: string;
  objectId: string;
  mail: string;
  firstName: string;
  lastName: string;
  glns: string[];
}

export interface RoleAssignment {
  id: string;
  roleName: string;
  roleDisplayName: string;
  scope: RoleScope;
  assignee: HealthUser;
  // Following only for display purposes
  organizationId: string | undefined;
  organizationName: string | undefined;
  locationId: string | undefined;
  locationName: string | undefined;
  ouId: string | undefined;
  organizationalUnitName: string | undefined;
}

export interface RoleAssignmentState extends EntityState<RoleAssignment> {
  loading: boolean;
  loaded: boolean;
}

export const roleAssignmentAdapter: EntityAdapter<RoleAssignment> =
  createEntityAdapter<RoleAssignment>({
    selectId: (roleAssignment) => roleAssignment.id,
    sortComparer: (roleAssignment1, roleAssignment2) =>
      roleAssignment1.roleName.localeCompare(roleAssignment2.roleName)
  });
