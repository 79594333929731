import { Component, Input } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest, map, Observable } from "rxjs";
import { Product } from "../../models";
import {
  associateProductToOrganization,
  disassociateProductFromOrganization,
  selectCurrentOrganizationAssociatedProducts,
  selectProducts,
  selectProductsLoading
} from "../../store";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrl: "./products.component.scss"
})
export class ProductsComponent {
  @Input() organizationId!: string;
  loading$: Observable<boolean>;
  products$: Observable<Product[]>;
  associatedProducts$: Observable<Product[]>;
  selectableProducts$: Observable<(Product & { enabled: boolean })[]>;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.loading$ = store.select(selectProductsLoading);
    this.products$ = store.select(selectProducts);
    this.associatedProducts$ = store.select(selectCurrentOrganizationAssociatedProducts);
    this.selectableProducts$ = combineLatest([this.products$, this.associatedProducts$]).pipe(
      map(([products, associatedProducts]) =>
        products.map((product) => ({
          ...product,
          enabled: associatedProducts.some(
            (associatedProduct) => associatedProduct.id === product.id
          )
        }))
      )
    );
  }

  associateProductToOrganization(product: Product) {
    this.store.dispatch(
      associateProductToOrganization({ organizationId: this.organizationId, product })
    );
  }

  disassociateProductFromOrganization(product: Product) {
    this.store.dispatch(
      disassociateProductFromOrganization({
        organizationId: this.organizationId,
        product: product
      })
    );
  }

  /*
  Checks if the product is enabled and dispatches the associateProductToOrganization or disassociateProductFromOrganization action
  */
  toggleProduct(product: Product & { enabled: boolean }) {
    if (product.enabled) {
      this.disassociateProductFromOrganization(product);
    } else {
      this.associateProductToOrganization(product);
    }
  }
}
