import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Invitation, RoleAssignment } from "src/app/organizations/models";
import { Session } from "../../../core/models";
import { LoginService } from "../../../core/services";
import { selectSession } from "../../../core/store";
import {
  acceptMyInvitation,
  declineMyInvitation,
  selectAllMyInvitations,
  selectAllMyRoleAssignments,
  selectMyInvitationsLoading,
  selectMyRoleAssignmentsLoading
} from "../../store";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"]
})
export class MyAccountComponent implements OnInit {
  fragment: string | null = "self-services";
  session$: Observable<Session>;
  invitations$: Observable<Invitation[]>;
  invitationLoading$: Observable<boolean>;
  roleAssignments$: Observable<RoleAssignment[]>;
  roleAssignmentsLoading$: Observable<boolean>;

  constructor(
    public loginService: LoginService,
    private store: Store,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.session$ = this.store.select(selectSession);
    this.invitations$ = this.store.select(selectAllMyInvitations);
    this.invitationLoading$ = this.store.select(selectMyInvitationsLoading);
    this.roleAssignments$ = this.store.select(selectAllMyRoleAssignments);
    this.roleAssignmentsLoading$ = this.store.select(selectMyRoleAssignmentsLoading);
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  acceptMyInvitation(invitationId: string) {
    this.store.dispatch(acceptMyInvitation({ invitationId }));
  }
  declineMyInvitation(invitationId: string) {
    this.store.dispatch(declineMyInvitation({ invitationId }));
  }

  resetPassword(email: string) {
    this.loginService.resetPassword(email);
  }

  editProfile() {
    this.loginService.editProfile();
  }

  manageTotp() {
    this.loginService.manageTotp();
  }

  manageWebAuthn() {
    this.loginService.manageWebAuthn();
  }

  getWindowLocation() {
    return this.location.path();
  }
}
