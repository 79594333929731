import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { MyAccountState } from "../../models";
import { reducer as myInvitationsReducer } from "./my-invitation.reducer";
import { reducer as myRoleAssignmentsReducer } from "./my-role-assignment.reducer";

export const reducers: ActionReducerMap<MyAccountState> = {
  myInvitations: myInvitationsReducer,
  myRoleAssignments: myRoleAssignmentsReducer
};

export const selectMyAccountState = createFeatureSelector<MyAccountState>("my-account");

export * as myInvitationsReducer from "./my-invitation.reducer";
export * as myRoleAssignmentsReducer from "./my-role-assignment.reducer";
