import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RoleAssignment } from "../../organizations/models";

export interface MyRoleAssignmentState extends EntityState<RoleAssignment> {
  loading: boolean;
  loaded: boolean;
}

export const MyRoleAssignmentAdapter: EntityAdapter<RoleAssignment> =
  createEntityAdapter<RoleAssignment>({
    selectId: (roleAssignment) => roleAssignment.id,
    sortComparer: (roleAssignment1, roleAssignment2) =>
      roleAssignment1.roleName.localeCompare(roleAssignment2.roleName)
  });
