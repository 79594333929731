import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { RoleAssignment } from "src/app/organizations/models";

@Injectable({ providedIn: "root" })
export class MyRoleAssignmentService {
  constructor(private http: HttpClient) {}

  getMyRoleAssignments(): Observable<Array<RoleAssignment>> {
    return this.http.get<RoleAssignment[]>(`${environment.api.uri}/roleAssignments`);
  }
}
