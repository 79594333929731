import { OrganizationState } from "./organization.state";
import { LocationState } from "./location.state";
import { OuState } from "./ou.state";
import { OrganizationIssuerState } from "./organization-issuer.state";
import { InvitationState } from "./invitation.state";
import { RoleAssignmentState } from "./role-assignment.state";
import { OrganizationAssignableRoleState } from "./organization-assignable-role.state";
import { ProductState } from "./product.state";
import { OrganizationAssociatedProductState } from "./organization-associated-products.state";

export interface MyOrganizationState {
  organizations: OrganizationState;
  locations: LocationState;
  ous: OuState;
  organizationAssignableRoles: OrganizationAssignableRoleState;
  organizationAssociatedProducts: OrganizationAssociatedProductState;
  organizationIssuers: OrganizationIssuerState;
  invitations: InvitationState;
  products: ProductState;
  roleAssignments: RoleAssignmentState;
}
