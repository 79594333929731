import { createReducer, on } from "@ngrx/store";
import * as InvitationActions from "../actions/my-invitation.actions";
import { MyInvitationAdapter, MyInvitationState } from "../../models";

const initialState: MyInvitationState = {
  ...MyInvitationAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    InvitationActions.loadMyInvitations,
    (state): MyInvitationState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    InvitationActions.loadMyInvitationsSuccess,
    (state, { invitations }): MyInvitationState =>
      MyInvitationAdapter.setAll(invitations, { ...state, loading: false, loaded: true })
  ),
  on(
    InvitationActions.loadMyInvitationsFailure,
    (state): MyInvitationState => ({ ...state, loading: false, loaded: false })
  ),

  on(
    InvitationActions.acceptMyInvitation,
    (state): MyInvitationState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    InvitationActions.acceptMyInvitationSuccess,
    (state, { invitationId }): MyInvitationState =>
      MyInvitationAdapter.removeOne(invitationId, { ...state, loading: false, loaded: true })
  ),
  on(
    InvitationActions.acceptMyInvitationFailure,
    (state, { invitationId }): MyInvitationState => ({ ...state, loading: false, loaded: false })
  ),

  on(
    InvitationActions.declineMyInvitation,
    (state): MyInvitationState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    InvitationActions.declineInvitationSuccess,
    (state, { invitationId }): MyInvitationState =>
      MyInvitationAdapter.removeOne(invitationId, { ...state, loading: false, loaded: true })
  ),
  on(
    InvitationActions.declineMyInvitationFailure,
    (state, { invitationId }): MyInvitationState => ({ ...state, loading: false, loaded: false })
  )
);

export const getMyInvitationsLoading = (state: MyInvitationState) => state.loading;
export const getMyInvitationsLoaded = (state: MyInvitationState) => state.loaded;
