import { createAction, props } from "@ngrx/store";
import { Product } from "../../models";

export const loadProducts = createAction("[Product/API] Load Products");

export const loadProductsSuccess = createAction(
  "[Product/API] Load Products Success",
  props<{ products: Product[] }>()
);

export const loadProductsFailure = createAction("[Product/API] Load Products Failure");
