import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, concatMap, map, of } from "rxjs";
import { NotificationService } from "src/app/core/services";
import { OrganizationService } from "../../services";
import {
  associateProductToOrganization,
  associateProductToOrganizationFailure,
  associateProductToOrganizationSuccess,
  disassociateProductFromOrganization,
  disassociateProductFromOrganizationFailure,
  disassociateProductFromOrganizationSuccess,
  loadOrganizationAssignableRoles,
  loadOrganizationAssociatedProducts,
  loadOrganizationAssociatedProductsFailure,
  loadOrganizationAssociatedProductsSuccess
} from "../actions";

@Injectable()
export class OrganizationAssociatedProductEffects {
  loadOrganizationassociatedProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganizationAssociatedProducts),
      concatMap((organizationId) =>
        this.organizationService.getAssociatedProducts(organizationId.organizationId).pipe(
          map((product) =>
            loadOrganizationAssociatedProductsSuccess({ organizationAssociatedProduct: product })
          ),
          catchError(() => of(loadOrganizationAssociatedProductsFailure()))
        )
      )
    );
  });

  asssociateProductToOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associateProductToOrganization),
      concatMap(({ organizationId, product }) =>
        this.organizationService.associateProductToOrganization(organizationId, product.id).pipe(
          map(() => associateProductToOrganizationSuccess({ organizationId, product })),
          catchError(() => of(associateProductToOrganizationFailure()))
        )
      )
    );
  });

  disassociateProductFromOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(disassociateProductFromOrganization),
      concatMap(({ organizationId, product }) =>
        this.organizationService
          .disassociateProductFromOrganization(organizationId, product.id)
          .pipe(
            map(() => disassociateProductFromOrganizationSuccess({ organizationId, product })),
            catchError(() => of(disassociateProductFromOrganizationFailure()))
          )
      )
    );
  });

  updateAssignableRolesUponAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associateProductToOrganizationSuccess, disassociateProductFromOrganizationSuccess),
      map(({ organizationId }) => loadOrganizationAssignableRoles({ organizationId }))
    );
  });

  notifyOrganizationAssociatedProductSuccess$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(associateProductToOrganizationSuccess),
        map(() => {
          this.notificationService.showTranslatedNotification(
            "notifications.associated_product_create_success",
            "confirmation"
          );
        })
      ) },
    { dispatch: false }
  );

  notifyOrganizationDisassociatedProductSuccess$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(disassociateProductFromOrganizationSuccess),
        map(() => {
          this.notificationService.showTranslatedNotification(
            "notifications.associated_product_delete_success",
            "confirmation"
          );
        })
      ) },
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {}
}
