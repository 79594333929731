import { InvitationEffects } from "./invitation.effects";
import { LocationEffects } from "./location.effects";
import { OrganizationAssignableRoleEffects } from "./organization-assignable-role.effects";
import { OrganizationAssociatedProductEffects } from "./organization-associated-product.effects";
import { OrganizationIssuerEffects } from "./organization-issuer.effects";
import { OrganizationEffects } from "./organization.effects";
import { OuEffects } from "./ou.effects";
import { ProductEffects } from "./product.effects";
import { RoleAssignmentEffects } from "./role-assignment.effects";

export const effects: any[] = [
  InvitationEffects,
  LocationEffects,
  OrganizationAssignableRoleEffects,
  OrganizationAssociatedProductEffects,
  OrganizationEffects,
  OrganizationIssuerEffects,
  OuEffects,
  ProductEffects,
  RoleAssignmentEffects
];

export * from "./invitation.effects";
export * from "./location.effects";
export * from "./organization-assignable-role.effects";
export * from "./organization-associated-product.effects";
export * from "./organization-issuer.effects";
export * from "./organization.effects";
export * from "./ou.effects";
export * from "./product.effects";
export * from "./role-assignment.effects";
