<div
  class="container"
  *ngrxLet="{
    session: session$,
    invitations: invitations$,
    invitationLoading: invitationLoading$,
    roleAssignments: roleAssignments$,
    roleAssignmentsLoading: roleAssignmentsLoading$
  } as ctx">
  <div class="row bg-gradient-1 padding-4 my-rounded-corner margin-bottom-4">
    <div class="col-xs-12">
      <h1 class="h1 white margin-2">{{ "header.main.label_my_account" | translate }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <sdx-tabs theme="centered">
        <sdx-tabs-item
          label="{{ 'my_account.self_services.header' | translate }}"
          icon-name="icon-gears"
          [selected]="fragment === 'self-services'"
          [href]="getWindowLocation() + '#self-services'">
          <div class="container">
            <div class="row flex-items-xs-center">
              <h5 class="navy">{{ "my_account.self_services.description" | translate }}</h5>
            </div>
            <div class="row row--gutters margin-top-0">
              <div class="col-xs-12 col-md-6">
                <sdx-card
                  size="small"
                  layout="interaction"
                  icon-name="icon-edit"
                  [attr.label]="'my_account.self_services.edit_profile_card.label' | translate"
                  label-aria-level="3"
                  [attr.href-label]="
                    'my_account.self_services.edit_profile_card.description' | translate
                  "
                  href="javascript:;"
                  (click)="editProfile()"></sdx-card>
              </div>
              <div class="col-xs-12 col-md-6">
                <sdx-card
                  size="small"
                  layout="interaction"
                  icon-name="icon-security"
                  [attr.label]="
                    ctx.session.hasPassword
                      ? ('my_account.self_services.reset_password_card.label' | translate)
                      : ('my_account.self_services.set_password_card.label' | translate)
                  "
                  label-aria-level="3"
                  [attr.href-label]="
                    ctx.session.hasPassword
                      ? ('my_account.self_services.reset_password_card.description' | translate)
                      : ('my_account.self_services.set_password_card.description' | translate)
                  "
                  href="javascript:;"
                  (click)="resetPassword(ctx.session.email)"></sdx-card>
              </div>

              <div class="col-xs-12 col-md-6" *ngIf="ctx.session?.hasPassword">
                <sdx-card
                  size="small"
                  layout="interaction"
                  icon-name="icon-phone-security"
                  [attr.label]="'my_account.self_services.totp_card.label' | translate"
                  label-aria-level="3"
                  [attr.href-label]="'my_account.self_services.totp_card.description' | translate"
                  href="javascript:;"
                  (click)="manageTotp()"></sdx-card>
              </div>
              <div class="col-xs-12 col-md-6">
                <sdx-card
                  size="small"
                  layout="interaction"
                  icon-name="icon-key"
                  [attr.label]="
                    ctx.session.webauthnRegistered
                      ? ('my_account.self_services.fido2_reset_card.label' | translate)
                      : ('my_account.self_services.fido2_register_card.label' | translate)
                  "
                  label-aria-level="3"
                  [attr.href-label]="
                    ctx.session.webauthnRegistered
                      ? ('my_account.self_services.fido2_reset_card.description' | translate)
                      : ('my_account.self_services.fido2_register_card.description' | translate)
                  "
                  href="javascript:;"
                  (click)="manageWebAuthn()"></sdx-card>
              </div>
            </div>
          </div>
        </sdx-tabs-item>
        <sdx-tabs-item
          label="{{ 'my_account.my_role_assignments.header' | translate }}"
          icon-name="icon-idcard"
          [selected]="fragment === 'my-role-assignments'"
          [href]="getWindowLocation() + '#my-role-assignments'">
          <div class="container">
            <div class="row flex-items-xs-center">
              <h5 class="navy">{{ "my_account.my_role_assignments.description" | translate }}</h5>
            </div>

            <div class="row" *ngIf="ctx.roleAssignments.length === 0">
              <div class="col-xs-12 padding-left-0 margin-top-2">
                <sdx-card
                  layout="notification"
                  icon-name="icon-information-circle"
                  label="{{ 'my_account.my_role_assignments.empty_help' | translate }}"
                  label-aria-level="3">
                </sdx-card>
              </div>
            </div>

            <div *ngIf="ctx.roleAssignmentsLoading" class="row">
              <sdx-loading-spinner
                size="large"
                sr-hint="Loading. Please wait."></sdx-loading-spinner>
            </div>

            <div class="row" *ngIf="ctx.roleAssignments.length > 0 && !ctx.roleAssignmentsLoading">
              <div class="col-xs-12 padding-left-0 margin-top-2">
                <div id="roleAssignments-table" class="table table--responsive table--highlight">
                  <div class="table__wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th data-type="text">
                            {{
                              "my_account.my_role_assignments.table_label_mail_address" | translate
                            }}
                          </th>
                          <th data-type="text">
                            {{ "my_account.my_role_assignments.table_label_role_name" | translate }}
                          </th>
                          <th data-type="text">
                            {{
                              "my_account.my_role_assignments.table_label_organization" | translate
                            }}
                          </th>
                          <th data-type="text">
                            {{ "my_account.my_role_assignments.table_label_location" | translate }}
                          </th>
                          <th data-type="text">
                            {{ "my_account.my_role_assignments.table_label_ou" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let roleAssignment of ctx.roleAssignments">
                          <td>{{ roleAssignment.assignee.mail }}</td>
                          <td>{{ roleAssignment.roleDisplayName }}</td>
                          <td>{{ roleAssignment.organizationName }}</td>
                          <td>{{ roleAssignment.locationName || "-" }}</td>
                          <td>{{ roleAssignment.organizationalUnitName || "-" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </sdx-tabs-item>
        <sdx-tabs-item
          label="{{ 'my_account.pending_invitations.header' | translate }}"
          icon-name="icon-status-incomplete"
          [selected]="fragment === 'pending-invitations'"
          [href]="getWindowLocation() + '#pending-invitations'">
          <div class="container">
            <div class="row flex-items-xs-center">
              <h5 class="navy">{{ "my_account.pending_invitations.description" | translate }}</h5>
            </div>

            <div class="row" *ngIf="ctx.invitations.length === 0">
              <div class="col-xs-12 padding-left-0 margin-top-2">
                <sdx-card
                  layout="notification"
                  icon-name="icon-information-circle"
                  label="{{ 'my_account.pending_invitations.empty_help' | translate }}"
                  label-aria-level="3">
                </sdx-card>
              </div>
            </div>

            <div *ngIf="ctx.invitationLoading" class="row">
              <sdx-loading-spinner
                size="large"
                sr-hint="Loading. Please wait."></sdx-loading-spinner>
            </div>
          </div>

          <div class="row" *ngIf="ctx.invitations.length > 0 && !ctx.invitationLoading">
            <div class="col-xs-12 padding-left-0 margin-top-2">
              <div id="invitations-table" class="table table--responsive table--highlight">
                <div class="table__wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th data-type="text">
                          {{
                            "my_account.pending_invitations.table_label_mail_address" | translate
                          }}
                        </th>
                        <th data-type="text">
                          {{ "my_account.pending_invitations.table_label_role_name" | translate }}
                        </th>
                        <th data-type="text">
                          {{
                            "my_account.pending_invitations.table_label_organization" | translate
                          }}
                        </th>
                        <th data-type="text">
                          {{ "my_account.pending_invitations.table_label_location" | translate }}
                        </th>
                        <th data-type="text">
                          {{ "my_account.pending_invitations.table_label_ou" | translate }}
                        </th>
                        <th data-type="text" class="text-align-right">
                          {{ "my_account.pending_invitations.table_label_actions" | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let invitation of ctx.invitations">
                        <td>{{ invitation.inviteeMailAddress }}</td>
                        <td>{{ invitation.roleDisplayName }}</td>
                        <td>{{ invitation.organizationName }}</td>
                        <td>{{ invitation.locationName || "-" }}</td>
                        <td>{{ invitation.organizationalUnitName || "-" }}</td>
                        <td class="text-align-right margin-0 padding-0">
                          <div class="toolbar" id="toolbarLocation">
                            <button
                              class="toolbar__item item--show"
                              aria-label="Accept"
                              (click)="acceptMyInvitation(invitation.id)">
                              <i
                                class="icon icon-check-mark-2-circle int-green--active"
                                aria-hidden="true"></i>
                              <span class="toolbar__label">{{
                                "my_account.pending_invitations.table_action_accept" | translate
                              }}</span>
                            </button>
                            <button
                              class="toolbar__item item--show"
                              aria-label="Decline"
                              (click)="declineMyInvitation(invitation.id)">
                              <i class="icon icon-cancel int-red--active" aria-hidden="true"></i>
                              <span class="toolbar__label">{{
                                "my_account.pending_invitations.table_action_decline" | translate
                              }}</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </sdx-tabs-item>
      </sdx-tabs>
    </div>
  </div>
</div>
