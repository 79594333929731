import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Organization, Product, Role } from "../models";

@Injectable({ providedIn: "root" })
export class OrganizationService {
  constructor(private http: HttpClient) {}

  getOrganizations(): Observable<Array<Organization>> {
    return this.http.get<Organization[]>(`${environment.api.uri}/organizations`);
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(
      `${environment.api.uri}/organizations/${organization.id}`,
      organization
    );
  }

  createOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${environment.api.uri}/organizations`, organization);
  }

  deleteOrganization(organizationId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api.uri}/organizations/${organizationId}`);
  }

  getAssignableRoles(organizationId: string): Observable<Role[]> {
    return this.http.get<Role[]>(
      `${environment.api.uri}/organizations/${organizationId}/assignableRoles`
    );
  }

  getAssociatedProducts(organizationId: string): Observable<Product[]> {
    return this.http.get<Product[]>(
      `${environment.api.uri}/organizations/${organizationId}/associatedProducts`
    );
  }

  associateProductToOrganization(organizationId: string, productId: string): Observable<void> {
    return this.http.put<void>(
      `${environment.api.uri}/organizations/${organizationId}/associatedProducts/${productId}`,
      {}
    );
  }

  disassociateProductFromOrganization(organizationId: string, productId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api.uri}/organizations/${organizationId}/associatedProducts/${productId}`
    );
  }
}
