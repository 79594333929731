import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import { ProductService } from "../../services";
import { loadProducts, loadProductsFailure, loadProductsSuccess } from "../actions";

@Injectable()
export class ProductEffects {
  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProducts),
      concatMap(({}) =>
        this.productService.getProducts().pipe(
          map((products) => loadProductsSuccess({ products })),
          catchError(() => of(loadProductsFailure()))
        )
      )
    );
  });

  constructor(
    private productService: ProductService,
    private actions$: Actions
  ) {}
}
