<div
  class="container"
  *ngrxLet="{
    selectableProducts: selectableProducts$,
    loading: loading$
  } as ctx">
  <div class="row flex-items-xs-center">
    <h5 class="navy">{{ "organization.products.description" | translate }}</h5>
  </div>

  <div class="row margin-top-2 flex-items-xs-center">
    <div class="col-xs">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organization.products.info_label' | translate }}"
        label-aria-level="4">
        <p>
          {{ "organization.products.info_text" | translate }}
        </p>
        <ul class="list single-line margin-0">
          <li>
            {{ "organization.products.info_bulletpoint_1" | translate }}
          </li>
          <li>
            {{ "organization.products.info_bulletpoint_2" | translate }}
          </li>
          <li>
            {{ "organization.products.info_bulletpoint_3" | translate }}
          </li>
        </ul>
      </sdx-card>
    </div>
  </div>

  <div class="row margin-top-2 flex-items-xs-center row--gutters">
    <div class="col-xs-12 col-md" *ngFor="let product of ctx.selectableProducts">
      <sdx-card>
        <div class="switch margin-0">
          <input
            [checked]="product.enabled"
            (click)="toggleProduct(product)"
            type="checkbox"
            role="switch"
            name="switch-{{ product.id }}"
            id="switch-{{ product.id }}" />
          <label class="margin-0" for="switch-{{ product.id }}">
            {{ product.name }}
          </label>
        </div>
      </sdx-card>
    </div>
  </div>

  <div class="row flex-items-xs-center" *ngIf="ctx.loading">
    <div class="col-xs-auto margin-top-2">
      <sdx-loading-spinner size="large"></sdx-loading-spinner>
    </div>
  </div>
</div>
