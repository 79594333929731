import { createAction, props } from "@ngrx/store";
import { RoleAssignment } from "src/app/organizations/models";

export const loadMyRoleAssignments = createAction("[RoleAssignment/API] Load MyRoleAssignments");
export const loadMyRoleAssignmentsSuccess = createAction(
  "[RoleAssignment/API] Load MyRoleAssignments Success",
  props<{ roleAssignments: RoleAssignment[] }>()
);
export const loadMyRoleAssignmentsFailure = createAction(
  "[RoleAssignment/API] Load MyRoleAssignments Failure"
);
