import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Role } from "./role";

export interface OrganizationAssignableRoleState extends EntityState<Role> {
  loading: boolean;
  loaded: boolean;
}

export const organizationAssignableRoleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (organizationAssignableRole) => organizationAssignableRole.id,
  sortComparer: (orgAssignableRole1, orgAssignableRole2) =>
    orgAssignableRole1.displayName.localeCompare(orgAssignableRole2.displayName)
});
