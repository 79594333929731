import { createReducer, on } from "@ngrx/store";
import {
  organizationAssociatedProductAdapter,
  OrganizationAssociatedProductState
} from "../../models";
import * as OrganizationAssociatedProductActions from "../actions/organization-associated-product.actions";

const initialState = {
  ...organizationAssociatedProductAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    OrganizationAssociatedProductActions.loadOrganizationAssociatedProducts,
    (state): OrganizationAssociatedProductState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    OrganizationAssociatedProductActions.loadOrganizationAssociatedProductsSuccess,
    (state, { organizationAssociatedProduct }): OrganizationAssociatedProductState =>
      organizationAssociatedProductAdapter.setAll(organizationAssociatedProduct, {
        ...state,
        loading: false,
        loaded: true
      })
  ),
  on(
    OrganizationAssociatedProductActions.loadOrganizationAssociatedProductsFailure,
    (state): OrganizationAssociatedProductState => ({ ...state, loading: false, loaded: false })
  ),
  on(
    OrganizationAssociatedProductActions.associateProductToOrganization,
    (state): OrganizationAssociatedProductState => ({ ...state, loading: true })
  ),
  on(
    OrganizationAssociatedProductActions.associateProductToOrganizationSuccess,
    (state, { product }): OrganizationAssociatedProductState =>
      organizationAssociatedProductAdapter.addOne(product, { ...state, loading: false })
  ),
  on(
    OrganizationAssociatedProductActions.disassociateProductFromOrganization,
    (state, { product }): OrganizationAssociatedProductState => ({ ...state, loading: true })
  ),
  on(
    OrganizationAssociatedProductActions.disassociateProductFromOrganizationSuccess,
    (state, { product }): OrganizationAssociatedProductState =>
      organizationAssociatedProductAdapter.removeOne(product.id, { ...state, loading: false })
  )
);

export const getOrganizationAssociatedProductLoading = (
  state: OrganizationAssociatedProductState
) => state.loading;
export const getOrganizationAssociatedProductLoaded = (state: OrganizationAssociatedProductState) =>
  state.loaded;
