import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of, switchMap, take, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { selectIsSessionSwisscomHealthOrgAdminWrite } from "src/app/core/store/selectors";
import { RoleScope } from "../models";
import {
  loadInvitations,
  loadLocation,
  loadOrganizationAssignableRoles,
  loadOrganizationAssociatedProducts,
  loadOrganizationIssuers,
  loadOrganizations,
  loadProducts,
  loadRoleAssignments,
  selectOrganizationById,
  selectOrganizationsLoaded
} from "../store";

export const organizationCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const store = inject(Store);

  return checkStore(store).pipe(
    switchMap(() => hasOrganization(route.params.organizationId, store))
  );
};

function hasOrganization(organizationId: string, store: Store): Observable<boolean> {
  // Combine selectIsSessionSwisscomHealthOrgAdminWrite with selectOrganizationById from store
  return combineLatest([
    store.select(selectIsSessionSwisscomHealthOrgAdminWrite),
    store.select(selectOrganizationById(organizationId))
  ]).pipe(
    switchMap(([isAdmin, organization]) => {
      if (!organization) {
        return of(false);
      }

      organization.childLocationIds.forEach((locationId) => {
        store.dispatch(loadLocation({ locationId }));
      });
      store.dispatch(loadInvitations({ scope: RoleScope.ORG, entityId: organizationId }));
      store.dispatch(loadRoleAssignments({ scope: RoleScope.ORG, entityId: organizationId }));
      store.dispatch(loadOrganizationAssignableRoles({ organizationId }));
      if (isAdmin) {
        store.dispatch(loadOrganizationIssuers({ organizationId }));
        store.dispatch(loadProducts());
        store.dispatch(loadOrganizationAssociatedProducts({ organizationId }));
      }

      return of(true);
    })
  );
}

function checkStore(store: Store): Observable<boolean> {
  return store.select(selectOrganizationsLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(loadOrganizations());
      }
    }),
    filter((loaded) => loaded),
    take(1)
  );
}
