import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Product } from "./product.state";

export interface OrganizationAssociatedProductState extends EntityState<Product> {
  loading: boolean;
  loaded: boolean;
}

export const organizationAssociatedProductAdapter: EntityAdapter<Product> =
  createEntityAdapter<Product>({
    selectId: (organizationAssociatedProduct) => organizationAssociatedProduct.id,
    sortComparer: (orgAssociatedProduct1, orgAssociatedProduct2) =>
      orgAssociatedProduct1.name.localeCompare(orgAssociatedProduct2.name)
  });
