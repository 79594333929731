import { createReducer, on } from "@ngrx/store";
import {
  MyRoleAssignmentAdapter,
  MyRoleAssignmentState
} from "../../models/my-role-assignment.state";
import * as MyRoleAssignmentActions from "../actions/my-role-assignment.actions";

const initialState: MyRoleAssignmentState = {
  ...MyRoleAssignmentAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    MyRoleAssignmentActions.loadMyRoleAssignments,
    (state): MyRoleAssignmentState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    MyRoleAssignmentActions.loadMyRoleAssignmentsSuccess,
    (state, { roleAssignments }): MyRoleAssignmentState =>
      MyRoleAssignmentAdapter.setAll(roleAssignments, { ...state, loading: false, loaded: true })
  ),
  on(
    MyRoleAssignmentActions.loadMyRoleAssignmentsFailure,
    (state): MyRoleAssignmentState => ({ ...state, loading: false, loaded: false })
  )
);

export const getMyRoleAssignmentsLoading = (state: MyRoleAssignmentState) => state.loading;
export const getMyRoleAssignmentsLoaded = (state: MyRoleAssignmentState) => state.loaded;
